* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.text-blue {
  color: #0a033c !important;
}

.bg-blue {
  background: #0a033c !important;
}

.icon {
  width: 35px;
  height: 35px;
  mix-blend-mode: multiply;
  object-fit: contain;
}

.line {
  height: 1.5px;
  width: 100%;
  max-width: 150px;
  background-color: black;
  margin: 0 10px;
}

.subject {
  width: 100%;
  max-width: 350px;
  height: 200px;
  border-radius: 8px;
  font-size: 24px;
  color: white;
  font-weight: 600;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0f0749;
}

.subject_box {
  max-width: 250px;
  max-height: 250px;
}

.class_box {
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: #ffcbd1;
  min-width: 250px;
  min-height: 150px;
}

.features_box {
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: #fff1f1;
  height: fit-content !important;
}

.features_box img {
  max-width: 30px;
  object-fit: contain;
}

.about_img{
  max-width: 300px;
}

.institute_box{
  max-width: 450px;
  max-height: 300px;
  min-height: 300px;
}

.institute_box img{
border-radius: 12px;
filter: brightness(0.9);
}

.icon_box{
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 18px !important;
  }
}
